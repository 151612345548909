import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import ShowMore from '@tedconf/react-show-more';

import ListingDetailFeed from './ListingDetailFeed';
import { collectionOrder } from '../../util/collectionOrder';

const key = 'FGdHrOEzme6yufWFz7ap7hUhuAaRsl';

const StyledListings = styled.div`
	[class*='ListingDetail'] {
		margin-bottom: 12rem;

		@media (max-width: 768px) {
			margin-bottom: 6rem;
		}
	}

	> .column {
		&:nth-child(odd) [class*='ListingDetail'] {
			@media (min-width: 769px) {
				margin-right: 5%;
			}
		}

		&:nth-child(even) [class*='ListingDetail'] {
			@media (min-width: 769px) {
				margin-left: 5%;
			}
		}
	}

	.more-button {
		display: block;
		margin: 0 auto;
		background: none;
		border: none;
		text-transform: uppercase;
		position: relative;
		cursor: pointer;

		&[disabled] {
			display: none;
		}

		&::before {
			content: '';
			width: 1px;
			height: 1rem;
			background-color: ${(props) => props.theme.colors.primary};
			position: absolute;
			left: 50%;
			bottom: -1.5rem;
			transform: translateX(-50%);
		}

		&::after {
			content: '';
			width: 0.75rem;
			height: 0.75rem;
			position: absolute;
			left: 50%;
			bottom: -1.5rem;
			transform: translateX(-50%) rotate(45deg);
			border-right: 1px solid ${(props) => props.theme.colors.primary};
			border-bottom: 1px solid ${(props) => props.theme.colors.primary};
		}
	}
`;

const CommListFromFeed = ({ city }) => {

	const url =
		`https://api.repliers.io/listings?pageNum=1&resultsPerPage=4&brokerage=Parker%20Coulter%20Realty%20Brokerage%20Inc&sortBy=createdOnDesc&city=${city}`;
	const options = {
		method: 'POST',
		headers: {
			accept: 'application/json',
			'content-type': 'application/json',
			'REPLIERS-API-KEY': key,
		},
	};

	const [listings, setListings] = useState([]);
	const [page, setPage] = useState(1);
	const [searchParams, setSearchParams] = useState({
		city: city,
		search: '',
		class: '',
		minPrice: '',
		maxPrice: '',
		minBeds: '',
		minBaths: '',
	});

	const getSlug = (listing) => {
		return `${listing?.address?.streetNumber
			? listing.address.streetNumber.toLowerCase()
			: ''
			}-${listing?.address?.streetName ? listing.address.streetName.toLowerCase() : ''
			}-${listing?.address?.streetSuffix
				? listing.address.streetSuffix.toLowerCase()
				: ''
			}-${listing?.address?.city ? listing.address.city.toLowerCase() : ''}-${listing.mlsNumber
			}`.replace(' ', '-');
	};

	useEffect(() => {
		fetch(url, options)
			.then((res) => res.json())
			.then((json) => {
				console.log(json);
				setListings(json.listings);
			})
			.catch((err) => console.error('error:' + err));
	}, []);

	const nextPage = () => {
		const url = `https://api.repliers.io/listings?pageNum=${page + 1}&resultsPerPage=4&brokerage=Parker%20Coulter%20Realty%20Brokerage%20Inc&sortBy=createdOnDesc&city=${city}`;

		console.log(url);
		const options = {
			method: 'POST',
			headers: {
				accept: 'application/json',
				'content-type': 'application/json',
				'REPLIERS-API-KEY': key,
			},
		};
		fetch(url, options)
			.then((res) => res.json())
			.then((json) => {
				console.log(json);
				setListings(listings.concat(json.listings));
			})
			.catch((err) => console.error('error:' + err));
		setPage(page + 1);
	};

	return (
		<StyledListings>
			<div className="columns is-multiline">
				{listings &&
					listings.map((listing, i) => (
						<div className="column is-half" key={getSlug(listing)}>
							<ListingDetailFeed listing={listing} />
						</div>
					))}
			</div>
			<button
				className="more-button"
				onClick={() => {
					nextPage();
				}}
			>
				Load More
			</button>
		</StyledListings>
	);
};

export default CommListFromFeed;
